import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MainNavigation from './MainNavigation';

export default class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header>

          <div className="about">
          <Link to="/"><button id="about-button" type="button">About</button></Link>
          </div>

          <div className="log">
          <button id="signup-button" type="button">Sign up</button>
          <button id="login-button" type="button">Log in</button>
          </div>
        </header>
      </React.Fragment>
    );
  }
}