import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div>
          <p>&copy; <a href="#">TWAS</a>, 2019</p>
          <nav>
            <ul>
              <li><Link to="/legalnotice">Legal notice</Link></li>
              <li><Link to="/privacypolicy">Privacy policy</Link></li>
            </ul>
          </nav>
        </div>
      </footer>
    );
  }
}
