import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

export default class Home extends Component {

  render() {
    return (
      <React.Fragment>

        <MetaTags>
          <title>TWAS - The world's a stage</title>
         { /* <link rel="shortcut icon" type="image/png" href={require("../assets/img/favicon.png")} sizes="96x96" /> */}
          <meta name="description" content="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." />
        </MetaTags>

        <img id="logo" src={require("../assets/img/logo.png")} /> <br />
        <div className="player">
          <p>Now playing: Van Weber - "Mystery"&nbsp;&nbsp;&nbsp;&nbsp;<button id="more">more of Van Weber</button></p>
        </div>
        <img id="graph" src={require("../assets/img/graph.png")} />
       
      </React.Fragment>
    );
  }
}